<template>
    <div>
        <p class="body-2 mb-0" :class="descriptionClass">{{ description }}</p>
        <slot :value="value" :set-value="setValue" name="value-display">
            <p :class="{'subtitle-1': true, 'font-weight-medium': true, 'black--text': true, 'notranslate': noTranslate}">{{ value }}</p>
        </slot>
    </div>
</template>

<script>
    export default {
        name: "BasicInformationItem",
        model: {
            prop: "value",
            event: "update:value"
        },
        props: {
            description: String,
            value: null,
            descriptionClass: {},
            noTranslate: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            setValue(newValue) {
                this.$emit("update:value", newValue)
            }
        }
    }
</script>