<template>
    <div>
        <v-calendar :short-weekdays="false" :events="getCalendarEvents" type="week" :day-format="() => { return '' }"
            :weekdays="weekdaysOrder" :weekday-format="formatWeekday" event-color="work-green"
            :locale="$store.getters['authModule/getUserLocale']" @click:event="handleClickEvent"
            :event-overlap-threshold="45" @click:time="handleClickTime" :class="{ 'calendar-max-height': maxHeight }">
        </v-calendar>
        <EditDayPlanningEventDialog ref="editDayPlanningEventDialog" :show="true"
            :confirmCallback="editDayPlanningEventCallback" :removeCallback="removeDayPlanningEventCallback" />
    </div>
</template>
<script>
import DaysOfWeek, { DaysOfWeekOrderStartMonday } from "@/shared/constants/DaysOfWeek"
import { addHours, min, startOfWeek, endOfWeek, addDays, isAfter, isBefore, roundToNearestMinutes } from "date-fns"
import EditDayPlanningEventDialog from "@/components/planning/EditDayPlanningEventDialog";
import { parseStringToDateTime, formatDateAsDay, formatDateTimeToString, formatDateAsTime, convertDateToWeekday } from "@/shared/utils/dateUtils";
import ClockingType from "@/shared/enums/clockingType";
import { translateClockingType } from "@/shared/utils/translateUtils";

export default {
    name: "DayPlanningComponent",
    model: {
        prop: 'value',
        event: 'update:value'
    },
    props: {
        value: Object,
        editable: {
            type: Boolean,
            default: false
        },
        maxHeight: {
            type: Boolean,
            default: false
        }
    },
    components: {
        EditDayPlanningEventDialog
    },
    data() {
        return {
            weekdaysOrder: DaysOfWeekOrderStartMonday
        }
    },
    methods: {
        formatWeekday(weekday) {
            return new Date(weekday?.date).toLocaleString(this.$store.getters["authModule/getUserLocale"], { weekday: this.$vuetify.breakpoint.xl ? 'long' : 'short' })
        },
        handleClickEvent(event) {
            if (this.editable) {
                this.$refs.editDayPlanningEventDialog?.open(
                    {
                        ...event.event,
                        start: parseStringToDateTime(event.event.start),
                        end: parseStringToDateTime(event.event.end),
                    }
                )
            }
        },
        handleClickTime(event) {
            if (this.editable) {
                const endOfDay = parseStringToDateTime(`${event.date} 23:59:00`)
                const lastStartTime = parseStringToDateTime(`${event.date} 23:30:00`)
                const start = min([roundToNearestMinutes(parseStringToDateTime(`${event.date} ${event.time}:00`), { nearestTo: 30 }), lastStartTime])
                const end = min([addHours(start, 1), endOfDay])

                this.$refs.editDayPlanningEventDialog?.open({ clockingType: ClockingType.WORK, start, end })
            }
        },
        editDayPlanningEventCallback(event) {
            //Check if event overlaps another
            if (this.modelValue.some(
                existingEvent => (
                    (
                        (isAfter(existingEvent.start, event.start) && isBefore(existingEvent.start, event.end))
                        || (isAfter(existingEvent.end, event.start) && isBefore(existingEvent.end, event.end))
                    ) && existingEvent.arrayIndex != event?.arrayIndex
                )
            )
            ) {
                this.$store.dispatch('setError', "Planningen mogen niet overlappen")
                return
            }

            if (event?.arrayIndex === 0 || event?.arrayIndex) {
                let newEvents = this.modelValue
                newEvents[event.arrayIndex] = event
                this.modelValue = newEvents
            } else {
                this.modelValue = [...this.modelValue, event]
            }
        },
        removeDayPlanningEventCallback(event) {
            if (event?.arrayIndex === 0 || event?.arrayIndex) {
                let newEvents = this.modelValue
                newEvents.splice(event.arrayIndex, 1)
                this.modelValue = newEvents
            }
        }
    },
    computed: {
        modelValue: {
            get() {
                const mappedPlanningEvents = []
                Object.entries(this.value).forEach(
                    ([weekday, planningEvents]) => {
                        if (planningEvents) {
                            planningEvents.forEach((planningEvent) => {
                                mappedPlanningEvents.push({
                                    ...planningEvent,
                                    clockingType: planningEvent.clockingType,
                                    start: planningEvent?.startTime ? parseStringToDateTime(`${formatDateAsDay(this.getCurrentWeekDaysMapped[weekday])} ${planningEvent.startTime}`) : planningEvent.start,
                                    end: planningEvent?.endTime ? parseStringToDateTime(`${formatDateAsDay(this.getCurrentWeekDaysMapped[weekday])} ${planningEvent.endTime}`) : planningEvent.end,
                                })
                            })
                        }
                    }
                )
                return mappedPlanningEvents.map((event, index) => (
                    {
                        ...event,
                        arrayIndex: index,
                    }
                ))
            },
            set(newValue) {
                const newPlanning = {}
                Object.keys(DaysOfWeek).forEach((weekDay) => {
                    newPlanning[weekDay] = newValue.filter(event => weekDay == convertDateToWeekday(event.start)).map(event => ({
                        ...event,
                        startTime: formatDateAsTime(event.start),
                        endTime: formatDateAsTime(event.end)
                    }))
                })
                this.$emit('update:value', newPlanning)
            }
        },
        getCurrentWeekDaysMapped() {
            const today = new Date()
            const options = { weekStartsOn: 1 }
            let mappedWeekDays = {}
            mappedWeekDays[DaysOfWeek.MONDAY] = startOfWeek(today, options)
            mappedWeekDays[DaysOfWeek.TUESDAY] = addDays(startOfWeek(today, options), 1)
            mappedWeekDays[DaysOfWeek.WEDNESDAY] = addDays(startOfWeek(today, options), 2)
            mappedWeekDays[DaysOfWeek.THURSDAY] = addDays(startOfWeek(today, options), 3)
            mappedWeekDays[DaysOfWeek.FRIDAY] = addDays(startOfWeek(today, options), 4)
            mappedWeekDays[DaysOfWeek.SATURDAY] = addDays(startOfWeek(today, options), 5)
            mappedWeekDays[DaysOfWeek.SUNDAY] = endOfWeek(today, options)
            return mappedWeekDays
        },
        getCalendarEvents() {
            return this.modelValue.map(planningEvent => ({
                ...planningEvent,
                name: translateClockingType(planningEvent.clockingType),
                start: formatDateTimeToString(planningEvent.start),
                end: formatDateTimeToString(planningEvent.end)
            }))
        },
    }
}
</script>
<style>
.v-calendar-daily_head-day-label {
    display: none;
}

.calendar-max-height {
    max-height: 250px !important;
    border-radius: 0 0 4px 4px;
}
</style>